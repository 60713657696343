import { createContext, useMemo, useCallback } from "react";
import useSupabase from "./useSupabase";
import { Tables } from "../../types/database";
import useTable from "./useTable";
import { useInsertFirstPoint } from "./usePoints";
import { SupabaseClient } from "@supabase/supabase-js";

export type Game = Tables<"games">;

type GamesContextType<Game> = {
  games: Game[];
  isLoading: boolean;
  updateGame: (game: Game) => Promise<Game[]>;
  deleteGame: (game: Game) => Promise<Game[]>;
  createGame: (game: Game, createFirstPoint?: boolean) => Promise<Game[]>;
  unendGame: (game: Game) => void;
  toggleHideGame: (game: Game) => void;
};

export const GamesContext = createContext<GamesContextType<Game>>({
  games: null,
  isLoading: false,
  updateGame: null,
  deleteGame: null,
  createGame: null,
  unendGame: null,
  toggleHideGame: null,
});

export function useGames({
  team_id,
  visible = true,
}: {
  team_id: number;
  visible?: boolean;
}): GamesContextType<Game> {
  const client = useSupabase();
  const key = useMemo(() => ["games", team_id], [team_id]);
  const { insertFirstPoint } = useInsertFirstPoint();
  const idAltPredicate = useCallback(
    (game: Game) =>
      JSON.stringify(
        [
          "location_latitude",
          "location_longitude",
          "opponent_name",
          "started_at",
          "team_id",
          "timezone_iana",
        ].map(
          (key: keyof Game): string | number | number[] | boolean => game?.[key]
        )
      ),
    []
  );
  const {
    rows: gamesUnsorted,
    isLoading,
    updateRow: updateGame,
    insertRows: insertGames,
    deleteRows: deleteGamesPartial,
  } = useTable<Game>({
    table: "games",
    visible,
    key,
    filter: `team_id=eq.${team_id}`,
    idAltPredicate,
    selectFunction: async (): Promise<Game[]> =>
      await selectFunction({ client, team_id }),
    updateFunction: async (game: Game): Promise<Game[]> =>
      await updateFunction({ client, game }),
    insertFunction: async (games: Game[]): Promise<Game[]> =>
      await insertFunction({ client, games }),
    deleteFunction: async (games: Game[]): Promise<Game[]> =>
      await deleteFunction({ client, games }),
  });

  const games = useMemo(
    (): Game[] =>
      gamesUnsorted.sort((a, b) => (a.created_at > b.created_at ? -1 : 1)),
    [gamesUnsorted]
  );

  const unendGame = async (game: Game) => {
    updateGame({ ...game, ended_at: null });
  };

  const toggleHideGame = async (game: Game) => {
    updateGame({ ...game, hidden: !game.hidden });
  };

  const deleteGame = async (game: Game): Promise<Game[]> => {
    await client.from("messages").delete().eq("game_id", game.id);
    await client.from("weather").delete().eq("game_id", game.id);
    await client.from("events").delete().eq("game_id", game.id);
    await client.from("points").delete().eq("game_id", game.id);
    return deleteGamesPartial([game]);
  };

  const createGame = async (
    game: Game,
    createFirstPoint: boolean = true
  ): Promise<Game[]> => {
    const insertGameResponse = await insertGames([game]);
    if (
      createFirstPoint &&
      insertGameResponse.length > 0 &&
      insertGameResponse[0].hasOwnProperty("id")
    ) {
      insertFirstPoint({
        team_id: game.team_id,
        game_id: insertGameResponse[0].id,
        willPull: true,
      });
    }
    return insertGameResponse;
  };

  return {
    games,
    isLoading,
    updateGame,
    createGame,
    deleteGame,
    unendGame,
    toggleHideGame,
  };
}

const selectFunction = async ({
  client,
  team_id,
}: {
  client: SupabaseClient;
  team_id: number;
}): Promise<Game[]> =>
  client
    .from("games")
    .select()
    .eq("team_id", team_id)
    .order("created_at", { ascending: false })
    .throwOnError()
    .then((result): Game[] => result.data);

export const updateFunction = async ({
  client,
  game,
}: {
  client: SupabaseClient;
  game: Game;
}): Promise<Game[]> =>
  client
    .from("games")
    .update(game)
    .eq("id", game.id)
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): Game[] => result.data);

export const insertFunction = async ({
  client,
  games,
}: {
  client: SupabaseClient;
  games: Game[];
}): Promise<Game[]> =>
  client
    .from("games")
    // remove `id` from each game
    .insert(games.map(({ id, ...rest }): Omit<Game, "id"> => rest))
    .throwOnError()
    .select()
    .order("created_at", { ascending: false })
    .throwOnError()
    .then((result): Game[] => result.data);

export const deleteFunction = async ({
  client,
  games,
}: {
  client: SupabaseClient;
  games: Game[];
}): Promise<Game[]> =>
  client
    .from("games")
    .delete()
    .in(
      "id",
      games.map((game) => game.id)
    )
    .throwOnError()
    .select()
    .throwOnError()
    .then((result): Game[] => result.data);
