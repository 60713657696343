import { GamePanelsEnum, isPanelEnabled } from "../../lib/gamePanels";
import { ReactNode, useContext } from "react";
import { Button, Col, Row, Toast, ToastContainer } from "react-bootstrap";
import { FaRegMinusSquare, FaRegPlusSquare } from "react-icons/fa";
import { TeamsContext } from "../../app/hooks/useTeams";
import { SessionContext } from "../../app/hooks/useSession";

export const GameViewPanel = ({
  id,
  children,
}: {
  id: GamePanelsEnum;
  children: React.ReactNode;
}) => (
  <Row className="d-flex flex-nowrap">
    <AddPanel panel_id={id}>{children}</AddPanel>
    <VisibilityWidget panel_id={id} />
  </Row>
);

const AddPanel = ({
  panel_id,
  children,
}: {
  panel_id: GamePanelsEnum;
  children: ReactNode;
}) => {
  const { editPanels } = useContext(SessionContext);
  const { team, isLoading } = useContext(TeamsContext);
  const show = !isLoading && isPanelEnabled(team, panel_id);

  return (
    <Col
      className={`d-flex flex-column ${show ? "" : "opacity-25"}`}
      style={{
        width: editPanels ? "calc(100% - 50px)" : "100%",
        minWidth: 0,
      }}>
      {editPanels || show ? children : null}
    </Col>
  );
};

const VisibilityWidget = ({ panel_id }: { panel_id: GamePanelsEnum }) => {
  const { editPanels } = useContext(SessionContext);
  const { team, updateTeam } = useContext(TeamsContext);
  const isVisible = isPanelEnabled(team, panel_id);

  const togglePanel = () => {
    updateTeam({
      ...team,
      ux_hide_panels: isPanelEnabled(team, panel_id)
        ? [...(team?.ux_hide_panels ?? []), panel_id]
        : (team?.ux_hide_panels ?? []).filter((p) => p != panel_id),
    });
  };

  return editPanels ? (
    <Col
      xs="auto"
      className="d-flex align-items-start justify-content-end"
      style={{ width: 50 }}>
      <Button variant="link" className="p-0 me-2">
        {isVisible ? (
          <FaRegMinusSquare
            className="text-muted"
            onClick={() => togglePanel()}
          />
        ) : (
          <FaRegPlusSquare
            className="text-muted"
            onClick={() => togglePanel()}
          />
        )}
      </Button>
    </Col>
  ) : null;
};

export const PanelVisibilityTeamWarning = ({
  show,
  delay = 5000,
  onClose,
}: {
  show: boolean;
  delay?: number;
  onClose: () => void;
}) => (
  <ToastContainer position="top-center" className="pt-4">
    <Toast onClose={onClose} show={show} delay={delay} autohide bg="danger">
      <Toast.Body className="lh-sm text-white text-center p-3">
        As a team administrator, changes you make to panel-visibility will
        affect all of this team&rsquo;s users.
      </Toast.Body>
    </Toast>
  </ToastContainer>
);

export default GameViewPanel;
