import { createContext, useMemo, useCallback } from "react";
import { findPointIndex, pointCreatedAtBetween } from "../../lib/pointsHelpers";
import useSupabase from "./useSupabase";
import { Tables } from "../../types/database";
import useTable from "./useTable";
import { SupabaseClient } from "@supabase/supabase-js";
import "react-loading-skeleton/dist/skeleton.css";
import { debugLog } from "../../lib/debug";

export type Point = Tables<"points">;

export type TurnoverThrowKeyType =
  | "d_them_forced"
  | "d_them_other"
  | "d_them_drop";

export type TurnoverHuckKeyType =
  | "hucks_incomplete_forced"
  | "hucks_incomplete_drop"
  | "hucks_incomplete_other";

type PointsContextType<Point> = {
  points: Point[]; // sorted unarchived points
  isLoading: boolean;
  updatePoint: (point: Point | Partial<Point>) => Promise<Point[]>;
  insertPoints: (points: Point[]) => Promise<Point[]>;
  upsertPoints: (points: Point[]) => Promise<Point[]>;
  deletePoints: (points: Point[]) => Promise<Point[]>;
  insertPointBefore: (point: Point) => Promise<string>;
  error: Error | null;
  clearError: () => void;
};

export const PointsContext = createContext<PointsContextType<Point>>({
  points: [],
  isLoading: false,
  updatePoint: null,
  insertPoints: null,
  upsertPoints: null,
  deletePoints: null,
  insertPointBefore: null,
  error: null,
  clearError: () => {},
});

export function usePoints({
  team_id,
  game_ids = [],
  visible = true,
}: {
  team_id: number;
  game_ids?: number[];
  visible?: boolean;
}): PointsContextType<Point> {
  const client = useSupabase();
  const key = useMemo(
    () => ["points", team_id, ...game_ids],
    [team_id, game_ids]
  );
  const idAltPredicate = useCallback(
    (point: Point) =>
      JSON.stringify(
        ["did_pull", "game_id", "team_id", "is_won"].map(
          (key: keyof Point): string | number | number[] | boolean =>
            point?.[key]
        )
      ),
    []
  );
  const {
    rows: points,
    isLoading,
    updateRow: updatePoint,
    insertRows: insertPoints,
    upsertRows: upsertPoints,
    deleteRows: deletePoints,
    error,
    clearError,
  } = useTable<Point>({
    table: "points",
    visible,
    key,
    filter: `game_id=in.(${(game_ids ?? []).join(",")})`,
    idAltPredicate,
    selectFunction: async (): Promise<Point[]> =>
      await selectFunction({ client, team_id, game_ids }),
    updateFunction: async (point: Point | Partial<Point>): Promise<Point[]> =>
      await updateFunction({ client, point }),
    insertFunction: async (points: Point[]): Promise<Point[]> =>
      await insertFunction({ client, points }),
    upsertFunction: async (points: Point[]): Promise<Point[]> =>
      await upsertFunction({ client, points }),
    deleteFunction: async (points: Point[]): Promise<Point[]> =>
      await deleteFunction({ client, points }),
  });

  const insertPointBefore = useCallback(
    async (pointAfter: Point) => {
      const pointIndex = findPointIndex({ point: pointAfter, points });
      // if point is not found
      if (pointIndex === -1) {
        console.log("insertPointBefore could not find point indexed.");
        return null;
      }
      const createdAtBefore =
        pointCreatedAtBetween(undefined, pointAfter) ?? new Date();
      let newPoint: Point = {
        created_at: createdAtBefore.toISOString(),
        game_id: pointAfter.game_id,
        team_id: pointAfter.team_id,
        // if subsequent point is a pull, point is likely won
        is_won: pointAfter.did_pull,
        // assume it is a hold
        did_pull: !pointAfter.did_pull,
      };
      if (pointIndex !== 0) {
        const pointBefore = points[pointIndex - 1];
        debugLog("pointBefore", pointBefore.id, pointBefore.created_at);
        // create a new Date object halfway between the timestamps
        const createdAtBetween =
          pointCreatedAtBetween(pointBefore, pointAfter) ?? new Date();
        debugLog("createdAtBetween", createdAtBetween);
        newPoint = {
          ...newPoint,
          did_pull: pointBefore.is_won,
          created_at: createdAtBetween.toISOString(),
        };
      }
      // return id of inserted point
      return insertPoints([newPoint]).then(
        (response) => response?.[0]?.id ?? null
      );
    },
    [points, insertPoints]
  );

  return {
    points,
    isLoading,
    updatePoint,
    insertPoints,
    upsertPoints,
    deletePoints,
    insertPointBefore,
    error,
    clearError,
  };
}

const selectFunction = async ({
  client,
  team_id,
  game_ids,
}: {
  client: SupabaseClient;
  team_id: number;
  game_ids: number[];
}): Promise<Point[]> =>
  team_id === undefined || team_id === null
    ? []
    : client
        .from("points")
        .select(selectColumns)
        .in(
          game_ids.length ? "game_id" : "team_id",
          game_ids.length ? game_ids : [team_id]
        )
        .eq("team_id", team_id)
        .eq("is_archived", false)
        .order("created_at", { ascending: true })
        .throwOnError()
        .then((result): Point[] => result.data);

export const updateFunction = async ({
  client,
  point,
}: {
  client: SupabaseClient;
  point: Point | Partial<Point>;
}): Promise<Point[]> =>
  client
    .from("points")
    .update(point)
    .eq("id", point.id)
    .throwOnError()
    .select(selectColumns)
    .throwOnError()
    .then((result): Point[] => result.data);

export const insertFunction = async ({
  client,
  points,
}: {
  client: SupabaseClient;
  points: Point[];
}): Promise<Point[]> =>
  client
    .from("points")
    // remove `id` from each point
    .insert(points.map(({ id, ...rest }): Omit<Point, "id"> => rest))
    .throwOnError()
    .select(selectColumns)
    .throwOnError()
    .then((result): Point[] => result.data);

export const upsertFunction = async ({
  client,
  points,
}: {
  client: SupabaseClient;
  points: Point[];
}): Promise<Point[]> =>
  client
    .from("points")
    .upsert(points)
    .throwOnError()
    .select(selectColumns)
    .throwOnError()
    .then((result): Point[] => result.data);

export const deleteFunction = async ({
  client,
  points,
}: {
  client: SupabaseClient;
  points: Point[];
}): Promise<Point[]> =>
  client
    .from("points")
    .delete()
    .in(
      "id",
      points.map((point) => point.id)
    )
    .throwOnError()
    .select(selectColumns)
    .throwOnError()
    .then((result): Point[] => result.data);

export function useInsertFirstPoint(): {
  insertFirstPoint: ({
    team_id,
    game_id,
    willPull,
  }: {
    team_id: number;
    game_id: number;
    willPull?: boolean;
  }) => Promise<Point[]>;
} {
  const client = useSupabase();
  const insertFirstPoint = ({
    team_id,
    game_id,
    willPull = false,
  }: {
    team_id: number;
    game_id: number;
    willPull?: boolean;
  }): Promise<Point[]> =>
    insertFunction({
      client,
      points: [
        {
          team_id,
          game_id,
          did_pull: willPull,
          is_won: null,
        },
      ],
    });
  return { insertFirstPoint };
}

export function useGamePoints(): {
  gamePoints: ({
    team_id,
    game_ids,
  }: {
    team_id: number;
    game_ids: number[];
  }) => Promise<Point[]>;
} {
  const client = useSupabase();
  return {
    gamePoints: async ({
      team_id,
      game_ids,
    }: {
      team_id: number;
      game_ids: number[];
    }): Promise<Point[]> => selectFunction({ client, team_id, game_ids }),
  };
}

// does not include `player_ids` (which is deprecated - subs stored in events now)
const selectColumns =
  "created_at,d_them_forced,d_them_other,d_us_forced,d_us_other,did_pull,endzone_failed_forced,endzone_failed_other,endzone_failed_unknown,endzone_scored,game_id,hucks_completed,hucks_incomplete_forced,hucks_incomplete_other,hucks_incomplete_unknown,id,is_archived,is_won,team_id,d_them_drop,hucks_incomplete_drop,endzone_failed_drop";
